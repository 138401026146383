import React from "react"
import Layout from '../../components/layout';
import BrandProfile from "../../components/brandprofile";
import { graphql } from "gatsby";

const MatkahuoltoKokemuksia = ({data}) => {
    return (    
      <Layout children={<BrandProfile slug={"matkahuolto"} serverUrl={data.site.siteMetadata.serverUrl} />} />
    );
};

export default MatkahuoltoKokemuksia;

export const query = graphql`
  query MatkahuoltoKokemuksiaQuery {
    site {
      siteMetadata {
        siteUrl
        serverUrl
        title
        description
      }
    }
  }
`